import React, { useState } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { makeStyles } from "@mui/styles";
import { submitSparql } from "../api/sparql";
import { dc, fetchWithHooks } from "../helpers";
import { Alert, Button } from "@mui/material";
import { ResultsTable } from "./ResultsTable";
import OcrxTabList from "./TabList";
require("codemirror/mode/sparql/sparql");
const useStyles = makeStyles({
    container: {
        width: "90%",
        margin: "auto",
    },
    editor: {
        boxShadow: "0px 0px 2px grey",
    },
    title: {},
});

const SparqlResult = ({ result }) => {
    return <pre>{JSON.stringify(result, null, 2)}</pre>;
};

const createData = (data) => {
    const mappingFn = (varName, varValue) => {
        return [varName, varValue["value"]];
    };
    const result = data["results"]["bindings"].map((object) =>
        dc(object, mappingFn)
    );
    return result;
};
const ResultDashboard = ({ data }) => {
    const sparqlEl = <SparqlResult result={data} />;
    const tableEl = (
        <ResultsTable
            data={createData(data)}
            title="SPARQL Results"
            headingMappings={data["head"]["vars"]}
            importantHeadings={data["head"]["vars"].slice(0, 2)}
        />
    );
    const tabsData = [
        { name: "Raw Response", component: sparqlEl },
        { name: "Table", component: tableEl },
    ];
    return (
        <div style={{ marginTop: "2rem" }}>
            <h3>Response</h3>
            <OcrxTabList tabs={tabsData} />
        </div>
    );
};

export const CodeEditor = () => {
    const initialVal = `# This is a template query that selects all Form in english
# Change line 13 ?sss rdfs:label "Route of administration"@en . to get ROA instead
# You can also change it to "Canadian clinical drug"@en .
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX owl: <http://www.w3.org/2002/07/owl#>
SELECT ?sLabel ?s
WHERE {
    ?s rdfs:label ?sLabel .
    ?s rdfs:subClassOf ?ss .
    ?ss rdfs:label ?ssLabel .
    ?ss rdfs:subClassOf ?sss .
    ?sss rdfs:label "Form"@en .
    FILTER (langMatches(lang(?sLabel), "en"))
}`;
    const [value, setValue] = useState(initialVal);
    const [data, setData] = useState({});
    const [hasData, setHasData] = useState(false);
    const [hasError, setHasError] = useState(false);

    const classes = useStyles();
    const options = {
        mode: "sparql",
        lineNumbers: true,
        autoRefresh: true,
        theme: "default",
    };
    const handleClick = async () => {
        const [dataNew, hasDataNew] = await submitSparql(value);
        setData(dataNew);
        setHasError(hasDataNew === false);
        setHasData(hasDataNew);
    };
    return (
        <div className={classes.container}>
            <h3 className={classes.title}>SPARQL Query Editor</h3>
            <p>
                Enter your SPARQL query here and click the RUN button to execute
                it. Queries are limited to 100 results and timeout after 5
                seconds.
            </p>
            <CodeMirror
                value={initialVal}
                options={options}
                editorDidMount={(editor) => {
                    editor.refresh();
                    for (let i = 0; i < 20; i++) {
                        setTimeout(function () {
                        editor.refresh();
                        }, i * 100)
                    }

                }}
                className={classes.editor}
                onChange={(editor, data, value) => {
                    setValue(value);
                }}
            />
            <Button onClick={()=>{}} disabled>Run Code</Button>
			<p> The SPARQL endpoint is temporariliy unavailable due to resources constraint. </p>
            {hasError && (
                <Alert severity="error">
                    Sorry! Something went wrong with the SPARQL Query.
                </Alert>
            )}
            {hasData && <ResultDashboard data={data} />}
        </div>
    );
};
